.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000000;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  border-bottom: 1px solid #333333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0 20px;
}

.name-container {
  /* Remove margin-left as we're using padding in the header */
}

.App-link {
  color: #61dafb;
}

.App-main {
  padding: 20px;
  color: white;
  background-color: #12202f; /* Slate color */
  min-height: calc(100vh - 60px);
  text-align: left; /* Add this line to left-justify the text */
}

a {
  color: #61dafb;
}

img {
  width: 30px;
  height: 30px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.linkedin-container {
  /* No additional styles needed */
}
